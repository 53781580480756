<template>
  <div>
    <!-- Business owner reg form -->
    <v-stepper v-model="e1">
      <v-stepper-header :color="$store.state.secondaryColor">
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
          :color="$store.state.secondaryColor"
        >
          <span class="font-14">{{ $t("personal information") }}</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" step="2">
          <span class="font-14">{{ $t("physical address") }}</span>
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form ref="personalInfo">
            <v-layout row wrap justify-end class="my-2">
              <v-flex xs12 sm12 md12 lg12 class="text-right">
                <v-btn
                  small
                  dark
                  class="mr-4"
                  :color="$store.state.primaryColor"
                  @click="demoPersonalInfo"
                >
                  <v-icon small left>gamepad</v-icon>
                  <span class="caption text-capitalize">{{ $t("demo") }}</span>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-container class="my-1">
              <v-layout row wrap justify-center>
                <v-flex xs12 sm12 md3 lg3 class="text-center elevation-2">
                  <v-card class="pa-2" max-width="344">
                    <v-img :src="imagePreview" height="200px"></v-img>
                  </v-card>

                  <input
                    type="file"
                    @change="previewPicture"
                    class="caption mt-2"
                  /> </v-flex
                ><v-flex xs12 sm12 md9 lg9 class="px-3 caption">
                  <v-layout row wrap class="mt-1">
                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("id number") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.ownerId"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("firstname") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.names.first"
                        :rules="rules.firstName"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("middlename") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.names.middle"
                        :rules="rules.middleName"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("lastname") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.names.last"
                        :rules="rules.lastName"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap class="mt-1">
                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("gender") }}:
                      </span>
                      <v-radio-group
                        v-model="register.gender"
                        row
                        :rules="rules.gender"
                      >
                        <v-radio value="male">
                          <template v-slot:label>
                            <div>M</div>
                          </template>
                        </v-radio>

                        <v-radio value="female">
                          <template v-slot:label>
                            <div>F</div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-flex>

                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize">
                        {{ $t("email") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.email"
                        :rules="rules.email"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("telephone") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.telephone"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("PO BOX") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.address.pobox"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>

          <v-layout row wrap class="mb-3 mr-1">
            <v-spacer></v-spacer>
            <v-flex xs12 sm4 md4 lg4 class="text-right">
              <v-btn
                dark
                small
                :color="$store.state.primaryColor"
                @click="continueStepper"
                >{{ $t("continue") }}
                <v-icon right dark> mdi-arrow-right-circle </v-icon></v-btn
              >
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>
      <v-stepper-items>
        <v-stepper-content step="2">
          <v-form ref="address">
            <v-layout row wrap class="my-2">
              <v-flex xs12 sm12 md12 lg12 class="text-right">
                <v-btn
                  small
                  dark
                  :color="$store.state.primaryColor"
                  class="mr-4"
                  @click="demoAddress"
                >
                  <v-icon small left>gamepad</v-icon>
                  <span class="caption text-capitalize">{{ $t("demo") }}</span>
                </v-btn>
                <v-btn
                  small
                  dark
                  :color="$store.state.secondaryColor"
                  class="mr-4"
                  @click="uploadData"
                >
                  <v-icon small left>save</v-icon>
                  <span class="caption text-capitalize">{{
                    $t("register")
                  }}</span>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-container class="my-1">
              <v-layout row wrap justify-center>
                <v-flex xs12 sm12 md12 lg12 class="px-3 caption">
                  <v-layout row wrap class="mt-1">
                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("country") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.address.country"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize">
                        {{ $t("county") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.address.county"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("city") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.address.city"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("street") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.address.street"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap class="mt-1">
                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("estate") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.address.estate"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize">
                        {{ $t("building") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="register.address.building"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 md3 lg3 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("house-suite-number") }}:
                      </span>
                      <v-text-field
                        dense
                        outlined
                        v-model="register.address.no"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>

          <v-layout row wrap class="mb-3 mr-1">
            <v-spacer></v-spacer>
            <v-flex xs12 sm4 md4 lg4 class="text-right">
              <v-btn
                dark
                small
                :color="$store.state.secondaryColor"
                @click="e1 = 1"
                ><v-icon left dark> mdi-arrow-left-circle </v-icon
                >{{ $t("back") }}</v-btn
              >
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
export default {
  data: () => ({
    imagePreview:
      "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_960_720.png",
    file: "",

    register: {
      ownerId: "",
      names: {
        first: "",
        middle: "",
        last: "",
      },
      email: "",
      telephone: "",
      avatar: "",
      gender: "",
      address: {
        country: "",
        county: "",
        city: "",
        pobox: "",
        street: "",
        estate: "",
        building: "",
        no: "",
      },
      dateCreated: new Date(),
    },

    e1: 1,
  }),

  computed: {
    rules() {
      return {
        firstName: [
          (v) => !!v || this.$t("first name is required"),
          (v) =>
            (v && v.length <= 10) ||
            this.$t("name must be less than 15 characters"),
        ],
        middleName: [
          (v) => !!v || this.$t("middle name is required"),
          (v) =>
            (v && v.length <= 10) ||
            this.$t("name must be less than 15 characters"),
        ],
        lastName: [
          (v) => !!v || this.$t("last name is required"),
          (v) =>
            (v && v.length <= 10) ||
            this.$t("name must be less than 15 characters"),
        ],
        email: [
          (v) => !!v || this.$t("e-mail is required"),
          (v) => /.+@.+\..+/.test(v) || this.$t("please enter a valid email"),
        ],
        gender: [(v) => (v || "").length > 0 || this.$t("please select one")],
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },

  methods: {
    continueStepper() {
      if (this.$refs.personalInfo.validate()) {
        this.e1 = 2;
      }

      // this.e1 = 2
    },

    demoPersonalInfo() {
      this.register.ownerId = "1234567";
      this.register.names.first = "James";
      this.register.names.middle = "M.";
      this.register.names.last = "Ndoto";
      this.register.email = "jm@email.com";
      this.register.telephone = "+254098765432";
      this.register.address.pobox = "00100";
      this.register.gender = "male";
    },

    demoAddress() {
      this.register.address.country = "Kenya";
      this.register.address.county = "Nairobi";
      this.register.address.city = "Nairobi";
      this.register.address.street = "Ngong Road";
      this.register.address.estate = "Ngong Road";
      this.register.address.building = "Bishop Magua Center";
      this.register.address.no = "1st FL FF10, Wing B";
    },

    uploadData() {
      if (this.$refs.address.validate()) {
        console.log("done:", JSON.stringify(this.register));
      }
    },

    previewPicture(event) {
      this.file = event.target.files[0];
      this.imagePreview = URL.createObjectURL(this.file);
    },
  },
};
</script>

<style></style>
